<template>
  <v-container>
    <v-row>
      <v-breadcrumbs large :items="items_nav">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card elevation="4">
      <v-card-title>
        <v-row>
          <div class="col">Novo Convite</div>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation @submit.prevent="submit()">
          <v-row v-if="!image_set">
            <v-col cols="12" md="4">
              <v-card
                elevation="4"
                height="160"
                width="160"
                @click="$refs.temaImage.$refs.input.click()"
              >
                <v-file-input
                  v-model="image"
                  :rules="rulesImagem"
                  accept="image/png, image/jpeg"
                  hidden
                  style="display: none"
                  ref="temaImage"
                  @change="upload()"
                ></v-file-input>
                <v-container grid-list-sm fluid>
                  <v-layout row wrap>
                    <v-img aspect-ratio="1">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-icon
                            large
                            center
                            v-text="'mdi-camera-plus'"
                          ></v-icon>
                        </v-row>
                      </template>
                    </v-img>
                  </v-layout>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="image_set" justify="center">
            <v-col cols="12" md="10">
              <tui-image-editor
                ref="tuiImageEditor"
                :include-ui="useDefaultUI"
                :options="options"
              ></tui-image-editor>
            </v-col>
          </v-row>
          <div v-if="image_set">
            <v-row >
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Nome</p>
              <v-text-field
                v-model="corNome"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do Nome"
                @change="changeColorNome()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuNome"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleNome" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corNome" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor da Mensagem</p>
              <v-text-field
                v-model="corMensagem"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor da mensagem"
                @change="changeColorMensagem()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuMensagem"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleMensagem" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corMensagem" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Mês</p>
              <v-text-field
                v-model="corMes"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do mês"
                @change="changeColorMes()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuMes"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleMes" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corMes" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Dia da Semana</p>
              <v-text-field
                v-model="corDia"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do Dia da Semana"
                @change="changeColorDia()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuDia"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleDia" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corDia" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Dia do Mês</p>
              <v-text-field
                v-model="corData"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do Dia do Mês"
                @change="changeColorData()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuData"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleData" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corData" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Endereço</p>
              <v-text-field
                v-model="corEndereco"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do Endereço"
                @change="changeColorEndereco()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuEndereco"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleEndereco" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corEndereco" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Horário</p>
              <v-text-field
                v-model="corHora"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do Horário"
                @change="changeColorHora()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuHora"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleHora" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corHora" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="shrink" style="min-width: 220px">
              <p>Cor do Texto do Horário</p>
              <v-text-field
                v-model="corHoraTexto"
                hide-details
                class="ma-0 pa-0"
                solo
                label="Cor do Texto do Horário"
                @change="changeColorHoraTexto()"
              >
                <template v-slot:append>
                  <v-menu
                    v-model="menuHoraTexto"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyleHoraTexto" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="corHoraTexto" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          </div>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                :items="temas"
                filled
                label="Tema"
                item-text="titulo"
                v-model="convite.tema"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-action class="d-flex flex-row-reverse">
        <div class="ma-4">
          <v-btn color="success" dark @click="submit()"> SALVAR </v-btn>
        </div>
      </v-card-action>
    </v-card>
    <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Realizando operação por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMessage" width="480">
      <v-card class="mx-auto" dark>
        <v-card-title>
          <span class="title font-weight-light">{{ message.title }}</span>
        </v-card-title>
        <v-card-text class="headline font-weight-bold">
          {{ message.body }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import database from "@/services/database";
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";

export default {
  data() {
    return {
      useDefaultUI: false,
      options: {
        includeUI: {
          menu: ["text"],
          uiSize: {
            width: "700px",
            height: "500px",
          },
        }, // for tui-image-editor component's "options" prop
        cssMaxWidth: 700,
        cssMaxHeight: 500,
      },
      corNome: '#1976D2FF',
      corMensagem: '#1976D2FF',
      corMes: '#1976D2FF',
      corDia: '#1976D2FF',
      corData: '#1976D2FF',
      corEndereco: '#1976D2FF',
      corHora: '#1976D2FF',
      corHoraTexto: '#1976D2FF',
		  mask: '!#XXXXXXXX',
		  menuNome: false,
      menuMensagem: false,
      menuMes: false,
      menuDia: false,
      menuData: false,
      menuEndereco: false,
      menuHora: false,
      menuHoraTexto: false,
      items_nav: [
        {
          text: "Sistema",
          disabled: false,
        },
        {
          text: "Convite",
          disabled: true,
        },
        {
          text: "Novo",
          disabled: true,
        },
      ],
      rulesImagem: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Tamanho da imagem deve ser inferior a 2 MB!",
      ],
      image_set: false,
      image: null,
      imageUrl: "",
      dialogProgress: false,
      dialogMessage: false,
      message: {
        title: "",
        body: "",
      },
      convite: {
        url: "",
        tema: {
          id: "",
          categorias: [],
          titulo: "",
          url:""
        },
        cores: {
          nome: "",
          mensagem: "",
          mes: "",
          dia: "",
          data: "",
          endereco: "",
          hora: "",
          horaTexto: ""
        },
      },
      textsIds: {
          nome: "",
          mensagem: "",
          mes: "",
          dia: "",
          data: "",
          endereco: "",
          hora: "",
          horaTexto: ""
        },
      temas: [],
    };
  },
  mounted() {
    this.loadTemas();
  },
  computed: {
    swatchStyleNome() {
      const { corNome } = this
      return {
        backgroundColor: corNome,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleMensagem() {
      const { corMensagem } = this
      return {
        backgroundColor: corMensagem,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleMes() {
      const { corMes } = this
      return {
        backgroundColor: corMes,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleDia() {
      const { corDia } = this
      return {
        backgroundColor: corDia,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleData() {
      const { corData } = this
      return {
        backgroundColor: corData,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleEndereco() {
      const { corEndereco } = this
      return {
        backgroundColor: corEndereco,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleHora() {
      const { corHora } = this
      return {
        backgroundColor: corHora,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    swatchStyleHoraTexto() {
      const { corHoraTexto } = this
      return {
        backgroundColor: corHoraTexto,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: "50%",
        transition: 'border-radius 200ms ease-in-out'
      }
    },
  },
  methods: {
    validate: async function () {},
    loadTemas: async function () {
      this.temas = await database.getAllTemas();
    },
    submit: async function () {
      try {
        this.dialogProgress = true;
        this.convite.cores.nome = this.corNome;
        this.convite.cores.mensagem = this.corMensagem;
        this.convite.cores.mes = this.corMes;
        this.convite.cores.dia = this.corDia;
        this.convite.cores.data = this.corData;
        this.convite.cores.endereco = this.corEndereco;
        this.convite.cores.hora = this.corHora;
        this.convite.cores.horaTexto = this.corHoraTexto;
        await database.addConvite(this.convite);
        this.message.title = "Operação realizada";
        this.message.body = "Alteração realizada com sucesso!";
        this.dialogProgress = false;
        this.dialogMessage = true;
      } catch (error) {
        console.log(error);
        this.dialogProgress = false;
        this.message.title = "Operação falhou";
        this.message.body = "Verifique sua conexão e tente novamente!";
        this.dialogMessage = true;
      }
    },
    uploadImage: function (event) {
      this.$refs.categoriaImage.click();
    },
    saveImage: function (event) {
      this.image = event.target.files[0];
    },
    upload: async function () {
      this.image_set = true;
      const reader = new FileReader();
      this.dialogProgress = true;
      var imageToSave = null;
      reader.readAsDataURL(this.image);

      if (this.image == null) {
        return;
      }
      reader.onload = (e) => {
        imageToSave = e.target.result;
      };
      reader.onloadend = async () => await this.save(imageToSave);
    },
    changeColorNome: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.nome, {
        fill: this.corNome
      })
    },
    changeColorMensagem: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.mensagem, {
        fill: this.corMensagem
      })
    },
    changeColorMes: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.mes, {
        fill: this.corMes
      })
    },
    changeColorDia: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.dia, {
        fill: this.corDia
      })
    },
    changeColorData: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.data, {
        fill: this.corData
      })
    },
    changeColorEndereco: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.endereco, {
        fill: this.corEndereco
      })
    },
    changeColorHora: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.hora, {
        fill: this.corHora
      })
    },
    changeColorHoraTexto: function() {
      this.$refs.tuiImageEditor.invoke("changeTextStyle", this.textsIds.horaTexto, {
        fill: this.corHoraTexto
      })
    },
    save: async function (image) {
      
      var result = await database.uploadImagemTema(
        image,
        this.image.name.split(".")[1],
        uuidv4(),
        this.image.name.split(".")[0]
      );
      this.convite.url = result.url;
      
      this.imageUrl = URL.createObjectURL(this.image);
      const resultAdd = await this.$refs.tuiImageEditor.invoke(
        "loadImageFromFile",
        this.image,
        "ConviteImage"
      );
      this.resizeEditor({
        height: resultAdd.newHeight,
        width: resultAdd.newWidth,
      });
      const { id: nomeId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "MARIA CLARA",
        {
          styles: {
            fill: this.corNome,
            fontSize: 49,
            fontFamily: "Century Gothic",
            textAlign: "center",
          },
          position: {
            x: 180,
            y: 300,
          },
        }
      );
      this.textsIds.nome = nomeId;
      const { id: mensagemId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "Venha comemorar meu 1˚ aninho!",
        {
          styles: {
            fill: this.corMensagem,
            fontSize: 21,
            fontFamily: "Lucida Handwriting New",
            textAlign: "center",
            textStyle: "italic",
          },
          position: {
            x: 135,
            y: 440,
          },
        }
      );
      this.textsIds.mensagem = mensagemId;
      const { id: mesId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "Abril",
        {
          styles: {
            fill: this.corMes,
            fontSize: 32,
            fontFamily: "Montserrat",
            textAlign: "center",
            fontWeight: "bold",
          },
          position: {
            x: 65,
            y: 554,
          },
        }
      );
      this.textsIds.mes = mesId;
      const { id: dataId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "20",
        {
          styles: {
            fill: this.corData,
            fontSize: 70,
            fontFamily: "Montserrat",
            textAlign: "center",
            fontWeight: "900",
          },
          position: {
            x: 63,
            y: 576,
          },
        }
      );
      this.textsIds.data = dataId;
      const { id: diaId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "Sexta",
        {
          styles: {
            fill: this.corDia,
            fontSize: 28,
            fontFamily: "Lucida Handwriting New",
            textAlign: "center",
            textStyle: "italic",
          },
          position: {
            x: 61,
            y: 640,
          },
        }
      );
      this.textsIds.dia = diaId;
      const { id: enderecoId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "Qi 25, lote 12/14, Guará II\nEdifício Mediterranée\nBloco D - Salão de Festas",
        {
          styles: {
            fill: this.corEndereco,
            fontSize: 19,
            fontFamily: "Montserrat",
            textAlign: "center",
            textStyle: "600",
          },
          position: {
            x: 218,
            y: 580,
          },
        }
      );
      this.textsIds.endereco = enderecoId;
      const { id: textoHorarioId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "às",
        {
          styles: {
            fill: this.corHoraTexto,
            fontSize: 19,
            fontFamily: "Montserrat",
            textAlign: "center",
            textStyle: "800",
          },
          position: {
            x: 528,
            y: 554,
          },
        }
      );
      this.textsIds.horaTexto = textoHorarioId;
      const { id: horaId } = await this.$refs.tuiImageEditor.invoke(
        "addText",
        "19h",
        {
          styles: {
            fill: this.corHora,
            fontSize: 69,
            fontFamily: "Montserrat",
            textAlign: "center",
            textStyle: "900",
          },
          position: {
            x: 528,
            y: 580,
          },
        }
      );
      this.textsIds.hora = horaId;
      await this.$refs.tuiImageEditor.invoke("changeSelectableAll", false);
      await this.$refs.tuiImageEditor.invoke("clearUndoStack");
      await this.$refs.tuiImageEditor.invoke("deactivateAll");

      this.dialogProgress = false;
    },
    resizeEditor: function (size) {
      const ref = this.$refs.tuiImageEditor.getRootElement();
      ref.style.height = `${size.height}px`;
      ref.style.width = `${size.width}px`;
    },
  },
};
</script>

<style>
</style>